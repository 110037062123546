@import '../../styles/variables.less';

.MenuList {
    list-style: none;
    padding-left: 0;

    li {
        font-size: 13px;
        font-weight: 500;
        padding: 8px 0;
    }

    li:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, .1);
    }

    &__btn {
        cursor: pointer;
        transition: all .2s ease-in-out;
        padding: 8px 20px;

        &:hover {
            color: @color-primary;
        }
    }

    &__btn.active {
        background-color: @color-primary;
        color: @white;
        border-radius: 20px;

        &:hover {
            color: @white;
        }
    }
}
