// @import "~animate.css/animate.css";
@import '../../node_modules/animate.css/animate.css';

.animated { visibility: hidden; }
.animated.visible { visibility: visible; }
.animate-hover {animation-fill-mode: both; }

.animated.zooming {
    animation-duration: 14s;
}

@keyframes zooming {
    50% {
      transform: scale3d(1.05, 1.05, 1.05);
    }
}

.zooming {
    animation-name: zooming;
}
