@import '../styles/variables.less';

.Hero {
    font-size: 12px;
    height: auto;

    @media (min-width: @screen-sm-min) {
        height: auto;
    }

    @media (min-width: @screen-md-min) {
        height: 100vh;
    }

    &__inittext {
        padding-right: 80px;

        @media (min-width: @screen-sm-min) {
            padding-right: 0;
        }
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        overflow: hidden;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    svg:hover path {
        pointer-events: none;
    }

    &__email {
        color: @color-primary;
        text-decoration: none;

        &:hover {
            color: @color-primary-hover;
        }
    }

    &__container {
        color: @white;
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        transition: all 0.3s ease-out;

        &-bottom {
            @media (min-width: @screen-md-min) {
                transform: translate3d(0, 140%, 0);
            }

            @media only screen and (max-height: 768px) {
                transform: translate3d(0, 0, 0);
            }
        }

        .fa-comments {
            margin-right: 8px;
            color: @color-primary;
        }

        h1 {
            line-height: 1.1;
            color: inherit;
            font-size: 52px;
            font-family: 'Roboto Condensed';
            font-weight: 300;
            padding-top: 40px;
            margin-bottom: 2px;
        }

        strong {
            line-height: 1.1;
            font-size: 65px;
            font-weight: 700;
        }

        h2 {
            margin-top: 0;
            color: @color-secondary;
            margin-bottom: 40px;
        }
    }

    &__buttons {
        display: table;
        margin-bottom: 20px;
    }

    &__button {
        text-decoration: none;

        &:hover > .fa-arrow-down,
        &:hover > .fa-file-pdf {
            opacity: 1;
            padding-left: 8px;
            width: 10px;
        }

        .fa-arrow-down,
        .fa-file-pdf {
            width: 10px;
            opacity: 0;
        }
    }
}
