body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn {
  display: inline-block;
  line-height: 1em;
  border: none;
  position: relative;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  font-weight: 600;
  margin-bottom: 2px;
  color: #fff;
  transition: all 0.2s ease-in-out;
  outline: 0;
}
.btn-sm {
  padding: 15px 32px 17px;
  border-radius: 30px;
}
.btn-lg {
  padding: 19px 36px 21px;
  border-radius: 40px;
  font-size: 14px;
}
.btn-default {
  background-color: transparent;
}
.btn-primary {
  color: #fff;
  background-color: #3aa8db;
}
.btn-primary:hover {
  color: #3aa8db;
  background-color: #fff;
}
.btn-circle {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 56px;
  width: 56px;
  border-radius: 50%;
}
.btn-circle-sm {
  height: 34px;
  width: 34px;
}
.btn-no-transition {
  transition: none;
}

.Hero {
  font-size: 12px;
  height: auto;
}
@media (min-width: 768px) {
  .Hero {
    height: auto;
  }
}
@media (min-width: 992px) {
  .Hero {
    height: 100vh;
  }
}
.Hero__inittext {
  padding-right: 80px;
}
@media (min-width: 768px) {
  .Hero__inittext {
    padding-right: 0;
  }
}
.Hero__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
}
.Hero__image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.Hero svg:hover path {
  pointer-events: none;
}
.Hero__email {
  color: #3aa8db;
  text-decoration: none;
}
.Hero__email:hover {
  color: #1f7eaa;
}
.Hero__container {
  color: #fff;
  position: relative;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  transition: all 0.3s ease-out;
}
@media (min-width: 992px) {
  .Hero__container-bottom {
    -webkit-transform: translate3d(0, 140%, 0);
            transform: translate3d(0, 140%, 0);
  }
}
@media only screen and (max-height: 768px) {
  .Hero__container-bottom {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
.Hero__container .fa-comments {
  margin-right: 8px;
  color: #3aa8db;
}
.Hero__container h1 {
  line-height: 1.1;
  color: inherit;
  font-size: 52px;
  font-family: 'Roboto Condensed';
  font-weight: 300;
  padding-top: 40px;
  margin-bottom: 2px;
}
.Hero__container strong {
  line-height: 1.1;
  font-size: 65px;
  font-weight: 700;
}
.Hero__container h2 {
  margin-top: 0;
  color: #caa669;
  margin-bottom: 40px;
}
.Hero__buttons {
  display: table;
  margin-bottom: 20px;
}
.Hero__button {
  text-decoration: none;
}
.Hero__button:hover > .fa-arrow-down,
.Hero__button:hover > .fa-file-pdf {
  opacity: 1;
  padding-left: 8px;
  width: 10px;
}
.Hero__button .fa-arrow-down,
.Hero__button .fa-file-pdf {
  width: 10px;
  opacity: 0;
}

.ImageCircle {
  border-radius: 50%;
  margin-bottom: 30px;
}

.MenuList {
  list-style: none;
  padding-left: 0;
}
.MenuList li {
  font-size: 13px;
  font-weight: 500;
  padding: 8px 0;
}
.MenuList li:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.MenuList__btn {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  padding: 8px 20px;
}
.MenuList__btn:hover {
  color: #3aa8db;
}
.MenuList__btn.active {
  background-color: #3aa8db;
  color: #fff;
  border-radius: 20px;
}
.MenuList__btn.active:hover {
  color: #fff;
}

.MenuSocial__social__links {
  list-style: none;
  padding-left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}
.MenuSocial .fa-github,
.MenuSocial .fa-linkedin-in,
.MenuSocial .fa-medium-m {
  color: #888 !important;
}

.MobileNav {
  top: 20px;
  right: 20px;
  position: fixed;
  z-index: 1;
  transition: all 0.3s ease-out;
}
.MobileNav-open > .MobileNav__Panel {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  opacity: 1;
}
.MobileNav__wrapper {
  padding: 30px;
  overflow-x: hidden;
  overflow-y: auto;
}
.MobileNav__btn {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  background-color: #3aa8db;
  color: #fff;
}
.MobileNav__Panel {
  position: fixed;
  background-color: #fff;
  opacity: 0;
  -webkit-transform: translateX(240px);
          transform: translateX(240px);
  top: 0;
  right: 0;
  height: 100%;
  width: 260px;
  z-index: 2;
  box-shadow: -2px 2px 61px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-out;
}
.MobileNav__Panel__btn {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 11px;
}

.Menu {
  position: fixed;
  width: 220px;
  z-index: 1;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  top: 15px;
  left: 15px;
  bottom: 15px;
  overflow-y: auto;
  transition: all 0.3s;
}
@media (min-width: 1200px) {
  .Menu {
    top: 25px;
    left: 25px;
    bottom: 25px;
    width: 240px;
  }
}
@media (min-width: 1481px) {
  .Menu {
    width: 270px;
  }
}
.Menu__logo {
  background-color: #3aa8db;
  text-align: right;
  padding: 60px 30px;
  margin-bottom: 10px;
}
.Menu__logo h1 {
  color: #fff;
  margin-bottom: 5px;
  line-height: 1;
  font-size: 46px;
  font-weight: 300;
  font-family: "Roboto Condensed";
}
.Menu__logo h1 strong {
  font-weight: 700;
}
.Menu__logo h2 {
  font-weight: 600;
  color: #31485e;
  font-size: 13px;
}
.Menu__list {
  padding: 0 25px;
  text-align: right;
}
.Menu .MenuSocial {
  position: static;
  text-align: center;
  padding: 0 25px;
  bottom: 0;
  left: 0;
  right: 0;
}

/* Document
   ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 */
html {
  font-family: sans-serif;
  line-height: 1.15;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
}
body {
  margin: 0;
}
/**
 * Render the `main` element consistently in IE.
 */
main {
  display: block;
}
/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}
/**
   * Remove the gray background on active links in IE 10.
   */
a {
  background-color: transparent;
}
/**
   * 1. Remove the bottom border in Chrome 57-
   * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
   */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  /* 2 */
}
/**
   * Add the correct font weight in Chrome, Edge, and Safari.
   */
b,
strong {
  font-weight: bolder;
}
/**
   * 1. Correct the inheritance and scaling of font size in all browsers.
   * 2. Correct the odd `em` font sizing in all browsers.
   */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}
/**
   * Add the correct font size in all browsers.
   */
small {
  font-size: 80%;
}
/**
   * Prevent `sub` and `sup` elements from affecting the line height in
   * all browsers.
   */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
/**
   * Remove the border on images inside links in IE 10.
   */
img {
  border-style: none;
}
/* Forms
     ========================================================================== */
/**
   * 1. Change the font styles in all browsers.
   * 2. Remove the margin in Firefox and Safari.
   */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}
/**
   * Show the overflow in IE.
   * 1. Show the overflow in Edge.
   */
button,
input {
  /* 1 */
  overflow: visible;
}
/**
   * Remove the inheritance of text transform in Edge, Firefox, and IE.
   * 1. Remove the inheritance of text transform in Firefox.
   */
button,
select {
  /* 1 */
  text-transform: none;
}
/**
   * Correct the inability to style clickable types in iOS and Safari.
   */
button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}
/**
   * Remove the inner border and padding in Firefox.
   */
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
/**
   * Restore the focus styles unset by the previous rule.
   */
button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}
/**
   * Correct the padding in Firefox.
   */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
/**
   * 1. Correct the text wrapping in Edge and IE.
   * 2. Correct the color inheritance from `fieldset` elements in IE.
   * 3. Remove the padding so developers are not caught out when they zero out
   *    `fieldset` elements in all browsers.
   */
legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */
}
/**
   * Add the correct vertical alignment in Chrome, Firefox, and Opera.
   */
progress {
  vertical-align: baseline;
}
/**
   * Remove the default vertical scrollbar in IE 10+.
   */
textarea {
  overflow: auto;
}
/**
   * 1. Add the correct box sizing in IE 10.
   * 2. Remove the padding in IE 10.
   */
[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}
/**
   * Correct the cursor style of increment and decrement buttons in Chrome.
   */
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
/**
   * 1. Correct the odd appearance in Chrome and Safari.
   * 2. Correct the outline style in Safari.
   */
[type='search'] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}
/**
   * Remove the inner padding in Chrome and Safari on macOS.
   */
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
/**
   * 1. Correct the inability to style clickable types in iOS and Safari.
   * 2. Change font properties to `inherit` in Safari.
   */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}
/* Interactive
     ========================================================================== */
/*
   * Add the correct display in Edge, IE 10+, and Firefox.
   */
details {
  display: block;
}
/*
   * Add the correct display in all browsers.
   */
summary {
  display: list-item;
}
/**
* Add the correct display in IE 10+.
*/
template {
  display: none;
}
/**
* Add the correct display in IE 10.
*/
[hidden] {
  display: none;
}
.bg-white {
  background-color: #fff;
}
.bg-dark {
  background-color: rgba(0, 0, 0, 0.7);
}
.bg-grey {
  background-color: #f8f8f8;
}
body {
  cursor: default;
  font-family: Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.6;
  color: #282828;
  font-size: 13px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.text-sm {
  font-size: 11px;
}
.container-padded {
  padding: 0 15px;
}
@media (min-width: 992px) {
  .container-padded {
    padding-left: 280px;
  }
}
@media (min-width: 1200px) {
  .container-padded {
    padding-left: 350px;
  }
}
@media (min-width: 1481px) {
  .container-padded {
    padding-left: 450px;
  }
}
.padding-v-60 {
  padding: 60px 0 0 0;
}
.section {
  position: relative;
}
@media (min-width: 768px) {
  .section {
    min-height: 100vh;
  }
}
.section__title {
  padding: 15px 35px 0;
  font-size: 1.2em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.6);
  margin: 0;
}
@media (min-width: 992px) {
  .section__title {
    padding-left: 300px;
  }
}
@media (min-width: 1200px) {
  .section__title {
    padding-left: 370px;
  }
}
@media (min-width: 1481px) {
  .section__title {
    padding-left: 470px;
  }
}
h3 {
  margin-bottom: 1em;
  font-size: 24px;
  font-family: 'Roboto Condensed';
  font-weight: 300;
}
h3 strong {
  font-weight: 700;
}
p {
  margin-bottom: 1.25em;
}
a {
  transition: all 0.2s ease-in-out;
}
.animated {
  visibility: hidden;
}
.animated.visible {
  visibility: visible;
}
.animate-hover {
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.animated.zooming {
  -webkit-animation-duration: 14s;
          animation-duration: 14s;
}
@-webkit-keyframes zooming {
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
            transform: scale3d(1.05, 1.05, 1.05);
  }
}
@keyframes zooming {
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
            transform: scale3d(1.05, 1.05, 1.05);
  }
}
.zooming {
  -webkit-animation-name: zooming;
          animation-name: zooming;
}

