@import '../../styles/variables.less';

.MobileNav {
    top: 20px;
    right: 20px;
    position: fixed;
    z-index: 1;
    transition: all .3s ease-out;
    
    &-open > .MobileNav__Panel {
        transform: translateX(0);
        opacity: 1;
    }

    &__wrapper {
        padding: 30px;
        overflow-x: hidden;
        overflow-y: auto;
    }

    &__btn {
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, .1);
        background-color: @color-primary;
        color: @white;
    }

    &__Panel {
        position: fixed;
        background-color: @white;
        opacity: 0;
        transform: translateX(240px);
        top: 0;
        right: 0;
        height: 100%;
        width: 260px;
        z-index: 2;
        box-shadow: -2px 2px 61px 0 rgba(0, 0, 0, .1);
        transition: all .3s ease-out;

        &__btn {
            position: absolute;
            top: 20px;
            right: 20px;
            font-size: 11px;
        }
    }
}
