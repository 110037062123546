@import '../../styles/variables.less';

.btn {
    display: inline-block;
    line-height: 1em;
    border: none;
    position: relative;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    width: fit-content;
    cursor: pointer;
    font-weight: 600;
    margin-bottom: 2px;
    color: @white;
    transition: all .2s ease-in-out;
    outline: 0;

    &-sm {
        padding: 15px 32px 17px;
        border-radius: 30px;
    }

    &-lg {
        padding: 19px 36px 21px;
        border-radius: 40px;
        font-size: 14px;
    }

    &-default {
        background-color: transparent;
    }

    &-primary {
        color: @white;
        background-color: @color-primary;

        &:hover {
            color: @color-primary;
            background-color: @white;
        }
    }

    &-circle {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 56px;
        width: 56px;
        border-radius: 50%;

        &-sm {
            height: 34px;
            width: 34px;
        }
    }

    &-no-transition {
        transition: none;
    }
}

