@import './variables.less';

.bg-white {
    background-color: @white;
}

.bg-dark {
    background-color: rgba(0,0,0,.7);
}

.bg-grey {
    background-color: #f8f8f8;
}

body {
    cursor: default;
    font-family: Roboto,'Helvetica Neue',Helvetica,Arial,sans-serif;
    font-weight: 400;
    line-height: 1.6;
    color: #282828;
    font-size: 13px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.text-sm {
    font-size: 11px
}

.container-padded {
    padding: 0 15px;

    @media (min-width: @screen-md-min) {
        // padding: 60px 15px;
        padding-left: 280px;
    }

    @media (min-width: @screen-lg-min) {
        padding-left: 350px;
    }

    @media (min-width: @screen-xlg-min) {
        padding-left: 450px;
    }
}

.padding-v-60 {
    padding: 60px 0 0 0;
}

.section {
    position: relative;

    @media (min-width: @screen-sm-min) {
        // min-height: 350px;
        min-height: 100vh;
    }

    &__title {
        padding: 15px 35px 0;
        font-size: 1.2em;
        text-transform: uppercase;
        color: rgba(0,0,0,.6);
        margin: 0;

        @media (min-width: @screen-md-min) {
            padding-left: 300px;
        }

        @media (min-width: @screen-lg-min) {
            padding-left: 370px;
        }

        @media (min-width: @screen-xlg-min) {
            padding-left: 470px;
        }
    }
}

h3 {
    margin-bottom: 1em;
    font-size: 24px;
    font-family: 'Roboto Condensed';
    font-weight: 300;

    strong {
        font-weight: 700;
    }
}

p {
    margin-bottom: 1.25em;
}

a {
    transition: all .2s ease-in-out;
}
