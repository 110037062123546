@import '../../styles/variables.less';

.Menu {
    position: fixed;
    width: 220px;
    z-index: 1;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, .1);
    background-color: @white;
    top: 15px;
    left: 15px;
    bottom: 15px;
    overflow-y: auto;
    transition: all .3s;

    @media (min-width: @screen-lg-min) {
        top: 25px;
        left: 25px;
        bottom: 25px;
        width: 240px;
    }

    @media (min-width: @screen-xlg-min) {
        width: 270px;
    }

    &__logo {
        background-color: @color-primary;
        text-align: right;
        padding: 60px 30px;
        margin-bottom: 10px;

        h1 {
            color: @white;
            margin-bottom: 5px;
            line-height: 1;
            font-size: 46px;
            font-weight: 300;
            font-family: "Roboto Condensed";

            strong {
                font-weight: 700;
            }
        }

        h2 {
            font-weight: 600;
            color: @text-secundary;
            font-size: 13px;
        }
    }

    &__list {
        padding: 0 25px;
        text-align: right;
    }

    .MenuSocial {
        position: static;
        text-align: center;
        padding: 0 25px;
        bottom: 0;
        left: 0;
        right: 0;
    }
}
