@import '../../styles/variables.less';

.MenuSocial {
    &__social__links {
        list-style: none;
        padding-left: 0;
        display: flex;
        justify-content: space-evenly;
    }

    .fa-github,
    .fa-linkedin-in,
    .fa-medium-m {
        color: @color-grey !important;
    }
}
